var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',{staticClass:"p-4"},[_c('CRow',[_c('CCol',{attrs:{"lg":"6"}},[_c('h5',[_vm._v("Incidents ("+_vm._s(_vm.count)+") ")])]),_c('CCol',{attrs:{"lg":"6"}},[_c('CButton',{class:'float-lg-right block ' + _vm.showCollapse ? 'collapsed' : null,staticStyle:{"float":"right"},attrs:{"size":"sm","aria-expanded":_vm.showCollapse ? 'true' : 'false',"aria-controls":"collapse1","color":"info"},on:{"click":function($event){_vm.showCollapse = !_vm.showCollapse}}},[_c('i',{staticClass:"fa fa-search"}),_vm._v("Filter"),_c('i',{class:_vm.showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'})]),(_vm.config.getPermission('incident').create)?_c('CButton',{staticClass:"float-lg-right",staticStyle:{"margin-right":"10px"},attrs:{"size":"sm","color":"success","target":"_blank ","href":"#/main/create_incident"}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("Add Incident ")]):_vm._e(),(_vm.config.getPermission('incident').download)?_c('CButton',{staticClass:"float-lg-right",staticStyle:{"margin-right":"10px"},attrs:{"size":"sm","color":"btn btn-outline-primary"},on:{"click":_vm.download}},[_c('i',{staticClass:"fa fa-download"}),_vm._v(" Download ")]):_vm._e()],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('CCollapse',{staticClass:"mt-2",attrs:{"id":"collapse1","show":_vm.showCollapse}},[_c('CCard',{staticClass:"p-4"},[_c('Search',{on:{"depot-search-query":_vm.search}})],1)],1)],1)],1),_c('hr'),(_vm.is_trucking())?_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('div',{staticClass:"table-responsive-sm table-responsive-md"},[_c('CDataTable',{attrs:{"loading":_vm.isLoading,"items":_vm.dataListAll.data,"fields":_vm.grid_columns,"hover":"","striped":"","outlined":""},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"10px","width":"100px","text-transform":"uppercase"}},[_c('CBadge',{attrs:{"color":_vm._f("incidentStatusBudgeColor")(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}},{key:"cost",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"10px","width":"100px"}},[_vm._v(" "+_vm._s(_vm._f("CurrencyFormatWithSign")(item.cost))+" ")])]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px"}},[_c('CDropdown',{attrs:{"toggler-text":"Select","size":"sm","color":"info"}},[(item.status=='Open')?_c('CDropdownItem',{on:{"click":function($event){return _vm.closedIncident(item)}}},[_c('i',{staticClass:"fa fa-close"},[_vm._v(" Close")])]):_vm._e(),(_vm.config.getPermission('incident').view)?_c('CDropdownItem',{on:{"click":function($event){return _vm.viewModal(item)}}},[_c('i',{staticClass:"fa fa-eye"},[_vm._v(" View")])]):_vm._e(),(_vm.config.getPermission('incident').update)?_c('CDropdownItem',{on:{"click":function($event){return _vm.updateModal(item)}}},[_c('i',{staticClass:"fa fa-pencil-square-o"},[_vm._v(" Edit")])]):_vm._e(),(_vm.config.getPermission('incident').delete)?_c('CDropdownItem',{on:{"click":function($event){return _vm.deleteRow(item)}}},[_c('i',{staticClass:"fa fa-trash"},[_vm._v(" Delete")])]):_vm._e()],1)],1)]}}],null,false,1011077684)}),_c('CPagination',{attrs:{"active-page":_vm.currentPageAll,"pages":Math.ceil(_vm.dataListAll.total / 20),"activePage":_vm.currentPageAll},on:{"update:activePage":[function($event){_vm.currentPageAll=$event},_vm.updatePageAll],"update:active-page":function($event){_vm.currentPageAll=$event}}})],1)])],1):_c('CRow',[_c('CCol',{attrs:{"lg":"12"}},[_c('div',{staticClass:"table-responsive-sm table-responsive-md"},[_c('CTabs',{attrs:{"variant":"pills","active-tab":0}},[_c('CTab',{attrs:{"title":"All"}},[_c('br'),_c('CDataTable',{attrs:{"loading":_vm.isLoading,"items":_vm.dataListAll.data,"fields":_vm.grid_columns,"hover":"","striped":"","outlined":""},scopedSlots:_vm._u([{key:"class_type",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(item.class_type)+" ")])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"10px","width":"100px","text-transform":"uppercase"}},[_c('CBadge',{attrs:{"color":_vm._f("incidentStatusBudgeColor")(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px"}},[_c('CDropdown',{attrs:{"toggler-text":"Select","size":"sm","color":"info"}},[(item.status=='Open')?_c('CDropdownItem',{on:{"click":function($event){return _vm.closedIncident(item)}}},[_c('i',{staticClass:"fa fa-close"},[_vm._v(" Close")])]):_vm._e(),(_vm.config.getPermission('incident').view)?_c('CDropdownItem',{on:{"click":function($event){return _vm.viewModal(item)}}},[_c('i',{staticClass:"fa fa-eye"},[_vm._v(" View")])]):_vm._e(),(_vm.config.getPermission('incident').update)?_c('CDropdownItem',{on:{"click":function($event){return _vm.updateModal(item)}}},[_c('i',{staticClass:"fa fa-pencil-square-o"},[_vm._v(" Edit")])]):_vm._e(),(_vm.config.getPermission('incident').delete)?_c('CDropdownItem',{on:{"click":function($event){return _vm.deleteRow(item)}}},[_c('i',{staticClass:"fa fa-trash"},[_vm._v(" Delete")])]):_vm._e()],1)],1)]}}])}),_c('CPagination',{attrs:{"active-page":_vm.currentPageAll,"pages":Math.ceil(_vm.dataListAll.total / 20),"activePage":_vm.currentPageAll},on:{"update:activePage":[function($event){_vm.currentPageAll=$event},_vm.updatePageAll],"update:active-page":function($event){_vm.currentPageAll=$event}}})],1),_c('CTab',{attrs:{"title":"Vehicle Incidents"}},[_c('br'),_c('CDataTable',{attrs:{"loading":_vm.isLoading,"items":_vm.dataListVehicle.data,"fields":_vm.grid_columns,"hover":"","striped":"","outlined":""},scopedSlots:_vm._u([{key:"class_type",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(item.class_type)+" ")])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"10px","width":"100px","text-transform":"uppercase"}},[_c('CBadge',{attrs:{"color":_vm._f("incidentStatusBudgeColor")(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px"}},[_c('CDropdown',{attrs:{"toggler-text":"Select","size":"sm","color":"info"}},[(item.status=='Open')?_c('CDropdownItem',{on:{"click":function($event){return _vm.closedIncident(item)}}},[_c('i',{staticClass:"fa fa-close"},[_vm._v(" Close")])]):_vm._e(),(_vm.config.getPermission('incident').view)?_c('CDropdownItem',{on:{"click":function($event){return _vm.viewModal(item)}}},[_c('i',{staticClass:"fa fa-eye"},[_vm._v(" View")])]):_vm._e(),(_vm.config.getPermission('incident').update)?_c('CDropdownItem',{on:{"click":function($event){return _vm.updateModal(item)}}},[_c('i',{staticClass:"fa fa-pencil-square-o"},[_vm._v(" Edit")])]):_vm._e(),(_vm.config.getPermission('incident').delete)?_c('CDropdownItem',{on:{"click":function($event){return _vm.deleteRow(item)}}},[_c('i',{staticClass:"fa fa-trash"},[_vm._v(" Delete")])]):_vm._e()],1)],1)]}}])}),_c('CPagination',{attrs:{"active-page":_vm.currentPageVehicle,"pages":Math.ceil(_vm.dataListVehicle.total / 20),"activePage":_vm.currentPageVehicle},on:{"update:activePage":[function($event){_vm.currentPageVehicle=$event},_vm.updatePageVehicle],"update:active-page":function($event){_vm.currentPageVehicle=$event}}})],1),_c('CTab',{attrs:{"title":"Equipment Incidents"}},[_c('br'),_c('CDataTable',{attrs:{"loading":_vm.isLoading,"items":_vm.dataListEquipment.data,"fields":_vm.grid_columns,"hover":"","striped":"","outlined":""},scopedSlots:_vm._u([{key:"class_type",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(item.class_type)+" ")])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"10px","width":"100px","text-transform":"uppercase"}},[_c('CBadge',{attrs:{"color":_vm._f("incidentStatusBudgeColor")(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])],1)]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"5px","width":"100px"}},[_c('CDropdown',{attrs:{"toggler-text":"Select","size":"sm","color":"info"}},[(item.status=='Open')?_c('CDropdownItem',{on:{"click":function($event){return _vm.closedIncident(item)}}},[_c('i',{staticClass:"fa fa-close"},[_vm._v(" Close")])]):_vm._e(),(_vm.config.getPermission('incident').view)?_c('CDropdownItem',{on:{"click":function($event){return _vm.viewModal(item)}}},[_c('i',{staticClass:"fa fa-eye"},[_vm._v(" View")])]):_vm._e(),(_vm.config.getPermission('incident').update)?_c('CDropdownItem',{on:{"click":function($event){return _vm.updateModal(item)}}},[_c('i',{staticClass:"fa fa-pencil-square-o"},[_vm._v(" Edit")])]):_vm._e(),(_vm.config.getPermission('incident').delete)?_c('CDropdownItem',{on:{"click":function($event){return _vm.deleteRow(item)}}},[_c('i',{staticClass:"fa fa-trash"},[_vm._v(" Delete")])]):_vm._e()],1)],1)]}}])}),_c('CPagination',{attrs:{"active-page":_vm.currentPageEquipment,"pages":Math.ceil(_vm.dataListEquipment.total / 20),"activePage":_vm.currentPageEquipment},on:{"update:activePage":[function($event){_vm.currentPageEquipment=$event},_vm.updatePageEquipment],"update:active-page":function($event){_vm.currentPageEquipment=$event}}})],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }