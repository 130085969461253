var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-4"},[_c('CRow',{staticClass:"mb-0"},[(!_vm.is_trucking())?_c('CCol',{attrs:{"lg":"3"}},[_c('v-select',{attrs:{"label":"setting_name","options":[
            {
              setting_name: 'Vehicle',
              id: 'vehicle'
            },
            {
              setting_name: 'Equipment',
              id: 'equipment'
            }
          ],"reduce":function (item) { return item.id; },"placeholder":"Asset Type"},model:{value:(_vm.asset_type),callback:function ($$v) {_vm.asset_type=$$v},expression:"asset_type"}})],1):_vm._e(),_c('CCol',{attrs:{"lg":"3"}},[_c('CInput',{attrs:{"size":"","placeholder":"Incident No."},model:{value:(_vm.incident_no),callback:function ($$v) {_vm.incident_no=$$v},expression:"incident_no"}})],1),_c('CCol',{attrs:{"lg":"3"}},[_c('CInput',{attrs:{"placeholder":"Reported By"},model:{value:(_vm.report_by),callback:function ($$v) {_vm.report_by=$$v},expression:"report_by"}})],1),_c('CCol',{attrs:{"lg":"3"}},[_c('v-select',{attrs:{"label":"setting_name","options":_vm.originList.data,"reduce":function (item) { return item.id; },"placeholder":"Incident Location"},model:{value:(_vm.current_location_id),callback:function ($$v) {_vm.current_location_id=$$v},expression:"current_location_id"}})],1),_c('CCol',{attrs:{"lg":"3"}},[_c('Datepicker',{attrs:{"input-class":"form-control","placeholder":"Incident Date from"},model:{value:(_vm.incident_date_from),callback:function ($$v) {_vm.incident_date_from=$$v},expression:"incident_date_from"}})],1),_c('CCol',{attrs:{"lg":"3"}},[_c('Datepicker',{attrs:{"input-class":"form-control","placeholder":"Incident Date to"},model:{value:(_vm.incident_date_to),callback:function ($$v) {_vm.incident_date_to=$$v},expression:"incident_date_to"}})],1),_c('CCol',{staticClass:"float-right",attrs:{"lg":"4"}},[_c('CButton',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm","color":"dark","variant":"outline"},on:{"click":_vm.search}},[_vm._v("Search")]),_c('CButton',{staticStyle:{"margin-right":"10px"},attrs:{"size":"sm","color":"dark","variant":"outline"},on:{"click":_vm.clearSearch}},[_vm._v("Clear Filter")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }