<template>
	<div>
		<CCard class="p-4">
			<CRow>
				
				<CCol lg="6">
					<h5>Incidents ({{ count }}) </h5>
				</CCol>
				<CCol lg="6">
					
					<CButton size="sm" style="float: right;"
						:class="'float-lg-right block ' + showCollapse ? 'collapsed' : null"
						:aria-expanded="showCollapse ? 'true' : 'false'" aria-controls="collapse1"
						@click="showCollapse = !showCollapse" color="info"><i class="fa fa-search"></i>Filter<i
							:class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i>
					</CButton>
				
					<CButton v-if="config.getPermission('incident').create" size="sm" style="margin-right: 10px"
						color="success" target="_blank " href="#/main/create_incident" class="float-lg-right"><i
							class="fa fa-plus"></i>Add Incident
					</CButton>
					<CButton 
						v-if="config.getPermission('incident').download"
						size="sm" style="margin-right: 10px" 
						color="btn btn-outline-primary" @click="download" 
						class="float-lg-right"><i class="fa fa-download"></i> Download
					</CButton>
				</CCol>
			</CRow>
			<CRow>
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
						<CCard class="p-4">
							<Search @depot-search-query="search" />
						</CCard>
					</CCollapse>
				</CCol>
			</CRow>
			<hr>
			<CRow v-if="is_trucking()">
				<CCol lg="12">
					<div class="table-responsive-sm table-responsive-md">
						<CDataTable :loading="isLoading" :items="dataListAll.data" :fields="grid_columns" hover striped
							outlined>
							<template #status="{ item }">
								<td style="padding: 10px; width: 100px; text-transform:uppercase;">
									<CBadge	 
										:color="item.status | incidentStatusBudgeColor">
										{{ item.status }} 
									</CBadge>
								</td>
							</template>
							<template #cost="{item}" >
								 <td style="padding: 10px; width: 100px; ">
									{{ item.cost|CurrencyFormatWithSign}}
         						 </td>
					      	</template>
							<template #action="{ item }">
								<td style="padding: 5px; width: 100px">
									<CDropdown toggler-text="Select" size="sm" color="info">
										<CDropdownItem v-if="item.status=='Open'" @click="closedIncident(item)"><i class="fa fa-close"> Close</i></CDropdownItem> 
										<CDropdownItem v-if="config.getPermission('incident').view"
											@click="viewModal(item)"><i class="fa fa-eye"> View</i></CDropdownItem> 
										<CDropdownItem v-if="config.getPermission('incident').update"
											@click="updateModal(item)"><i class="fa fa-pencil-square-o"> Edit</i></CDropdownItem>
										<CDropdownItem v-if="config.getPermission('incident').delete"
											@click="deleteRow(item)"><i class="fa fa-trash"> Delete</i></CDropdownItem>
									</CDropdown>
								</td>
							</template>
						</CDataTable>
						<CPagination :active-page.sync="currentPageAll" :pages="Math.ceil(dataListAll.total / 20)"
							:activePage="currentPageAll" @update:activePage="updatePageAll" />

					</div>
				</CCol>

			</CRow>

			<CRow v-else>
				<CCol lg="12">
					<div class="table-responsive-sm table-responsive-md">
						<CTabs variant="pills" :active-tab="0">
							<CTab title="All">
								<br />

								<CDataTable 
									:loading="isLoading" 
									:items="dataListAll.data" 
									:fields="grid_columns" 
									hover
									striped 
									outlined>
									<template #class_type="{ item }">
										<td style="text-transform:capitalize;">
											{{ item.class_type }}
										</td>
									</template>
									
									<template #status="{ item }">
										<td style="padding: 10px; width: 100px; text-transform:uppercase;">
											<CBadge :color="item.status|incidentStatusBudgeColor">
												{{ item.status }} 
											</CBadge>
										</td>
									</template>
									<template #action="{ item }">
										<td style="padding: 5px; width: 100px">
											<CDropdown toggler-text="Select" size="sm" color="info">
												<CDropdownItem v-if="item.status=='Open'" @click="closedIncident(item)"><i class="fa fa-close"> Close</i></CDropdownItem> 
												<CDropdownItem v-if="config.getPermission('incident').view"
													@click="viewModal(item)"><i class="fa fa-eye"> View</i></CDropdownItem> 
												<CDropdownItem v-if="config.getPermission('incident').update"
													@click="updateModal(item)"><i class="fa fa-pencil-square-o"> Edit</i></CDropdownItem>
												<CDropdownItem v-if="config.getPermission('incident').delete"
													@click="deleteRow(item)"><i class="fa fa-trash"> Delete</i></CDropdownItem>
											</CDropdown>
										</td>
									</template>
								</CDataTable>
								<CPagination :active-page.sync="currentPageAll" :pages="Math.ceil(dataListAll.total / 20)"
									:activePage="currentPageAll" @update:activePage="updatePageAll" />
							</CTab>

							<CTab title="Vehicle Incidents">
								<br />

								<CDataTable 
									:loading="isLoading" 
									:items="dataListVehicle.data" 
									:fields="grid_columns" 
									hover
									striped 
									outlined>
									<template #class_type="{ item }">
										<td style="text-transform:capitalize;">
											{{ item.class_type }}
										</td>
									</template>
									
									<template #status="{ item }">
										<td style="padding: 10px; width: 100px; text-transform:uppercase;">
											<CBadge :color="item.status|incidentStatusBudgeColor">
												{{ item.status }} 
											</CBadge>
										</td>
									</template>
									<template #action="{ item }">
										<td style="padding: 5px; width: 100px">
											<CDropdown toggler-text="Select" size="sm" color="info">
												<CDropdownItem v-if="item.status=='Open'" @click="closedIncident(item)"><i class="fa fa-close"> Close</i></CDropdownItem> 
												<CDropdownItem v-if="config.getPermission('incident').view"
													@click="viewModal(item)"><i class="fa fa-eye"> View</i></CDropdownItem> 
												<CDropdownItem v-if="config.getPermission('incident').update"
													@click="updateModal(item)"><i class="fa fa-pencil-square-o"> Edit</i></CDropdownItem>
												<CDropdownItem v-if="config.getPermission('incident').delete"
													@click="deleteRow(item)"><i class="fa fa-trash"> Delete</i></CDropdownItem>
											</CDropdown>
										</td>
									</template>
								</CDataTable>
								<CPagination :active-page.sync="currentPageVehicle"
									:pages="Math.ceil(dataListVehicle.total / 20)" :activePage="currentPageVehicle"
									@update:activePage="updatePageVehicle" />
							</CTab>
							<CTab title="Equipment Incidents">
								<br />

								<CDataTable 
									:loading="isLoading" 
									:items="dataListEquipment.data" 
									:fields="grid_columns" 
									hover
									striped 
									outlined>
									<template #class_type="{ item }">
										<td style="text-transform:capitalize;">
											{{ item.class_type }}
										</td>
									</template>
									
									<template #status="{ item }">
										<td style="padding: 10px; width: 100px; text-transform:uppercase;">
											<CBadge :color="item.status|incidentStatusBudgeColor">
												{{ item.status }} 
											</CBadge>
										</td>
									</template>
									<template #action="{ item }">
										<td style="padding: 5px; width: 100px">
											<CDropdown toggler-text="Select" size="sm" color="info">
												<CDropdownItem v-if="item.status=='Open'" @click="closedIncident(item)"><i class="fa fa-close"> Close</i></CDropdownItem> 
												<CDropdownItem v-if="config.getPermission('incident').view"
													@click="viewModal(item)"><i class="fa fa-eye"> View</i></CDropdownItem> 
												<CDropdownItem v-if="config.getPermission('incident').update"
													@click="updateModal(item)"><i class="fa fa-pencil-square-o"> Edit</i></CDropdownItem>
												<CDropdownItem v-if="config.getPermission('incident').delete"
													@click="deleteRow(item)"><i class="fa fa-trash"> Delete</i></CDropdownItem>
											</CDropdown>
										</td>
									</template>
								</CDataTable>
								<CPagination :active-page.sync="currentPageEquipment"
									:pages="Math.ceil(dataListEquipment.total / 20)" :activePage="currentPageEquipment"
									@update:activePage="updatePageEquipment" />
							</CTab>
						</CTabs>
					</div>
				</CCol>

			</CRow>

		</CCard>
	</div>
</template>
<script>
import config from '../config.js';
import axios from '../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import moment from 'moment';
import '../style.css';

export default {
	mounted() {

		// this.getEquipmentCurrentLocation();
		this.getVehicle();
		this.getOrigin();
		this.getEquipment();
		this.getDataEquipment();
		this.getDataVehicle();
		this.getDataAll();
		for (var i = 0; i < document.querySelectorAll('.nav-item').length; i++) {
			document.querySelectorAll('.nav-item')[i].onclick = () => {
				this.current_tab = document.querySelector(".nav-link.active").innerHTML;
				this.setCount();
			}
		}
	},
	data() {
		return {
			current_tab: "All",
			count: 0,
			title: '',
			isLoading: false,
			config,
			formModal: false,
			showViewModal: false,
			dataListAllEquipment: [],
			dataList: [],
			equipmentCurrentLocationList: [],
			dataListAll: {
				data: []
			},
			dataListVehicle: {
				data: []
			},
			dataListEquipment: {
				data: []
			},
			depotName: "",
			dataParams: {
				vehicle_id: "",
				equipment_id: "",
				asset_no: "",
				current_location_id: "",
				type: "",
				incident_date: "",
				report_by: "",
				description: "",
				remarks: "",
				class_type: "vehicle"
			},
			fields: [
				{
					key: 'incident_id',
					label: 'Incident No.'
				},
				{
					key: 'class_type',
					label: 'Class'
				},

				{
					key: 'number_label',
					label: 'Equipment No.'
				},
				{
					key: 'plate_no_label',
					label: 'Plate No.'
				},
				{
					key: 'current_location_id_label',
					label: 'Incident Location'
				},
				{
					key: 'persons_involved',
					label: 'Person Involved'
				},
				{
					key: 'incident_date_label',
					label: 'Incident Date'
				},
				{
					key: 'report_by',
					label: 'Reported By'
				},
				{
					key: 'status',
					label: 'Status'
				},
				{
					key: 'action',
					label: 'Action',
					_style: { width: '20px' }
				}
			],
			fields_trucking: [
				{
					key: 'incident_id',
					label: 'Incident No.'
				},
				{
					key: 'current_location_id_label',
					label: 'Incident Location'
				},
				{
					key: 'incident_date_label',
					label: 'Incident Date'
				},
				{
					key: 'report_by',
					label: 'Reported By'
				},
				{
					key: 'cost',
					label: 'Amount'
				},
				{
					key: 'status',
					label: 'Status'
				},
				{
					key: 'action',
					label: 'Action',
					_style: { width: '20px' }
				}

			],
			currentPageAll: 1,
			currentPageVehicle: 1,
			currentPageEquipment: 1,
			currentPage: 1,
			currentPageInactive: 1,
			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filter: {
				incident_no: "",
				class_type: "",
				report_by: "",
				asset_type: "",
				remarks: "",
				current_location_id: "",
				asset_no: "",
				incident_date_from: "",
				incident_date_to: "",
			},

			depotList: {
				data: []
			},
			personnelTypeList: {
				data: []
			},
			equipmentList: {
				data: []
			},
			originList: {
				data: []
			},
			customerList: {
				data: []
			},
			vehicleList: {
				data: []
			},
			// equipmentCurrentLocationList: {
			// 	data: []
			// },
			moment
		}
	},
	name: 'Tables',
	computed: {
		grid_columns() {
			if (this.is_trucking()) {
				return this.fields_trucking;
			}

			return this.fields;
		}
	},
	components: { Search, Datepicker, vSelect },
	methods: {
		closedIncident(data) {
			if (data.status == 'Closed') {
				Swal.fire(
					'Oops...',
					'Incident is already closed.',
					'warning'
				)
			}
			else {
				Swal.fire({
					title: 'Are you sure you want to close this incident? ',
					text: "You won't be able to edit this anymore!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, close it!'
				}).then((result) => {
					if (result.isConfirmed) {
						Swal.showLoading();
						axios.post(config.api_path + "/incident-closed/" + data.id)
							.then(response => {
								this.$showLoading(false)
								this.getVehicle();
								this.getOrigin();
								this.getEquipment();
								this.getDataEquipment();
								this.getDataVehicle();
								this.getDataAll();
								Swal.fire(
									'Closed',
									'Incident has been closed successfully.',
									'success'
								)
							})
							.catch(err => {
								Swal.fire(
									'Oops...',
									'Something went wrong! Please contact your administrator.',
									'error'
								)
								this.$showLoading(false)
							})
					}
				})
			}
		},
		setCount() {
			if (this.current_tab == "All") {
				this.count = this.dataListAll.total
			}
			if (this.current_tab == "Vehicle Incidents") {
				this.count = this.dataListVehicle.total
			}
			if (this.current_tab == "Equipment Incidents") {
				this.count = this.dataListEquipment.total
			}
		},
		updatePageAll(data) {
			this.currentPageAll = data;
			this.getDataAll();
		},
		updatePageVehicle(data) {
			this.currentPageVehicle = data;
			this.getDataVehicle()
		},
		updatePageEquipment(data) {
			this.currentPageEquipment = data;
			this.getDataEquipment();
		},

		toggleModal() {
			this.formModal = !this.formModal;
			this.title = "Add Incident"
		},

		submit() {
			var ax = {};
			if (this.dataParams.equipment_id == "") {
				this.dataParams.equipment_id = null;
			}
			if (this.dataParams.vehicle_id == "") {
				this.dataParams.vehicle_id = null;
			}
			if (this.dataParams.incident_date == "") {
				this.dataParams.incident_date = null;
			}
			else {
				this.dataParams.incident_date = moment(this.dataParams.incident_date).startOf('day').unix();
			}

			if (this.editMode) {
				ax = axios.put(config.api_path + "/incident/" + this.selectedRow.id, this.dataParams)
			}
			else {
				ax = axios.post(config.api_path + "/incident", this.dataParams)
			}
			ax.then(response => {
				var text = 'Incident successfully added!';
				if (this.editMode) {
					text = 'Incident successfully updated!';
				}
				Swal.fire({
					title: 'Success!',
					text,
					icon: 'success',
				})
				this.formModal = false
				this.editMode = false;
				this.dataParams = {
					vehicle_id: "",
					equipment_id: "",
					asset_no: "",
					current_location_id: "",
					type: "",
					incident_date: "",
					report_by: "",
					description: "",
					remarks: "",
					class_type: "vehicle"
				}
				this.getDataAll();
				this.getDataEquipment();
				this.getDataVehicle();
			})
		},

		search(event) {
			this.filter = event;
			this.getDataAll();
			this.getDataEquipment();
			this.getDataVehicle();
		},
		getOrigin() {

			axios.get(config.api_path + '/reference/settings-list', {
				params: {
					setting_type: 'origin'
				}
			})
				.then(response => {
					this.originList = response.data;
				})

		},

		getDataAll() {
			this.isLoading = true;
			var current_location_id = this.filter.current_location_id;
			if (current_location_id == "all" || current_location_id == null) {
				current_location_id = ""
			}

			if (this.filter.class_type == "all") {
				this.filter.class_type = ""
			}

			var incident_date_from = this.filter.incident_date_from;
			if (incident_date_from != "") {
				incident_date_from = moment(incident_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
			}

			var incident_date_to = this.filter.incident_date_to;
			if (incident_date_to != "") {
				incident_date_to = moment(incident_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
				incident_date_to = incident_date_to + 86399;
			}

			axios.get(config.api_path + '/incident?current_location_id=' + current_location_id +
				'&class_type=' + this.filter.class_type +
				'&asset_type=' + this.filter.asset_type +
				'&id=' + this.filter.incident_no +
				'&report_by=' + this.filter.report_by +
				'&incident_date_from=' + incident_date_from +
				'&incident_date_to=' + incident_date_to +
				'&page=' + this.currentPageAll + '&limit=20')
				.then(response => {
					this.dataListAll = response.data;
					this.dataListAll.data = this.dataListAll.data.map((value, index) => {

						// value.plate_no_label = value.vehicle.plate_no ? value.vehicle.plate_no ?? '' : '';
						// value.current_location_id_label = value.current_location.setting_name
						value.cost = value?.cost ?? '';
						value.persons_involved = value?.persons_involved ?? '';
						value.plate_no_label = '';
						value.number_label = '';
						value.current_location_id_label = "-"
						if (value.class_type == 'vehicle') {
							value.plate_no_label = value.vehicle ? value.vehicle?.plate_no ?? '' : '';

						}
						if (value.class_type == 'equipment') {
							value.number_label = value.equipment.asset_no

						}

						if (value.current_location_id != null) {
							value.current_location_id_label = value.current_location.setting_name
						}
						else {
							value.current_location_id_label = "-"
						}
						// if(value.class_type == 'vehicle'){
						// 	value.number_label = value.vehicle.plate_no
						// 	if(value.current_location_id != null){
						// 		value.current_location_id_label = value.current_location_vehicle.setting_name
						// 	} 
						// 	else{
						// 		value.current_location_id_label = "N/A"
						// 	}
						// }
						// if(value.class_type == 'equipment'){
						// 	value.number_label = value.equipment.asset_no
						// 	if(value.current_location_equipment != null){
						// 		value.current_location_id_label = value.current_location_equipment.customer_name
						// 	} 
						// 	else{
						// 		value.current_location_id_label = "N/A"
						// 	}
						// }

						// console.log(value.incident_date)
						if (value.incident_date != null) {
							value.incident_date_label = moment.unix(value.incident_date).format('MM/DD/YYYY')
							// value.incident_date_label = value.incident_date
						}
						else {
							value.incident_date_label = "-"
						}
						return value;
					});

					// console.log(this.dataListAll.data, 'tiutiti')
					this.setCount()
				})
				.catch(err => {
					console.error(err)
					this.isLoading = false;
				})
				.finally(fin => {
					console.log(fin)
					this.isLoading = false;
				})
		},

		getDataEquipment() {

			this.isLoading = true;
			var current_location_id = this.filter.current_location_id;
			if (current_location_id == "all" || current_location_id == null) {
				current_location_id = ""
			}

			var incident_date_from = this.filter.incident_date_from;
			if (incident_date_from != "") {
				incident_date_from = moment(incident_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
			}

			var incident_date_to = this.filter.incident_date_to;
			if (incident_date_to != "") {
				incident_date_to = moment(incident_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
				incident_date_to = incident_date_to + 86399;
			}

			axios.get(config.api_path + '/incident?current_location_id=' + current_location_id +
				'&class_type=equipment' +
				'&asset_type=' + this.filter.asset_type +
				'&id=' + this.filter.incident_no +

				'&incident_date_from=' + incident_date_from +
				'&incident_date_to=' + incident_date_to +
				'&page=' + this.currentPage + '&limit=20')
				.then(response => {
					this.dataListEquipment = response.data;
					this.dataListEquipment.data = this.dataListEquipment.data.map((value, index) => {
						value.cost = value?.cost ?? '';
						value.persons_involved = value?.persons_involved ?? '';
						value.plate_no_label = '';
						value.number_label = '';
						value.current_location_id_label = '-';
						if (value.class_type == 'vehicle') {
							value.plate_no_label = value.vehicle ? value.vehicle?.plate_no ?? '' : '';
							if (value.current_location_id != null) {
								value.current_location_id_label = value.current_location.setting_name
							}
							else {
								value.current_location_id_label = "-"
							}
						}
						if (value.class_type == 'equipment') {
							value.number_label = value.equipment.asset_no
							if (value.current_location_id != null) {
								value.current_location_id_label = value.current_location.setting_name
							}
							else {
								value.current_location_id_label = "-"
							}
						}

						console.log(value.current_location_id_label)
						if (value.incident_date != null) {
							value.incident_date_label = moment.unix(value.incident_date).format('MM/DD/YYYY')
							// value.incident_date_label = value.incident_date
						}
						else {
							value.incident_date_label = "-"
						}
						return value;
					});
					this.setCount()
				})
				.catch(err => {
					console.error(err)
					this.isLoading = false;
				})
				.finally(fin => {
					console.log(fin)
					this.isLoading = false;
				})
		},

		getDataVehicle() {
			this.isLoading = true;

			var current_location_id = this.filter.current_location_id;
			if (current_location_id == "all" || current_location_id == null) {
				current_location_id = ""
			}

			var incident_date_from = this.filter.incident_date_from;
			if (incident_date_from != "") {
				incident_date_from = moment(incident_date_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
			}

			var incident_date_to = this.filter.incident_date_to;
			if (incident_date_to != "") {
				incident_date_to = moment(incident_date_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
				incident_date_to = incident_date_to + 86399;
			}

			axios.get(config.api_path + '/incident?current_location_id=' + current_location_id +
				'&class_type=vehicle' +
				'&asset_type=' + this.filter.asset_type +
				'&id=' + this.filter.incident_no +

				'&incident_date_from=' + incident_date_from +
				'&incident_date_to=' + incident_date_to +
				'&page=' + this.currentPageInactive + '&limit=20')
				.then(response => {
					this.dataListVehicle = response.data;
					this.dataListVehicle.data = this.dataListVehicle.data.map((value, index) => {

						// value.number_label = value.equipment.asset_no ? value.equipment.asset_no ?? '' : '';
						// value.plate_no_label = value.vehicle.plate_no ? value.vehicle.plate_no ?? '' : '';
						// value.current_location_id_label = value.current_location.setting_name
						value.cost = value?.cost ?? '';
						value.persons_involved = value?.persons_involved ?? '';
						value.plate_no_label = '';
						value.number_label = '';
						value.current_location_id_label = "-"
						if (value.class_type == 'vehicle') {
							value.plate_no_label = value.vehicle ? value.vehicle?.plate_no ?? '' : '';
							if (value.current_location_id != null) {
								value.current_location_id_label = value.current_location.setting_name
							}
							else {
								value.current_location_id_label = "-"
							}
						}
						if (value.class_type == 'equipment') {
							value.number_label = value.equipment.asset_no
							if (value.current_location_id != null) {
								value.current_location_id_label = value.current_location.setting_name
							}
							else {
								value.current_location_id_label = "-"
							}
						}

						console.log(value.incident_date)
						if (value.incident_date != null) {
							value.incident_date_label = moment.unix(value.incident_date).format('MM/DD/YYYY')

						}
						else {
							value.incident_date_label = "-"
						}
						return value;
					});
					this.setCount()
				})
				.catch(err => {
					console.error(err)
					this.isLoading = false;
				})
				.finally(fin => {
					console.log(fin)
					this.isLoading = false;
				})
		},

		updateModal(item) {

			// this.selectedRow = item;
			// this.dataParams = item;
			// this.dataParams.incident_date = moment.unix(this.dataParams.incident_date).format('MMMM DD, YYYY')

			window.open('/#/main/update_incident/' + item.id, '_blank');
			// this.formModal = true;
			// this.editMode = true;
			// this.title = "Update Incident"

		},

		viewModal(item) {
			if (this.is_roadwise())
				window.open('/#/main/create_incident/' + item.id + '/1', '_blank');
			else
				window.open('/#/main/view_incident/' + item.id + '', '_blank');
			// this.selectedRow = item;
			// this.dataParams = item;
			// this.showViewModal = true; 

		},

		deleteRow(item) {
			Swal.fire({
				icon: 'warning',
				title: 'Are you sure you want to delete this incident',
				showCancelButton: true,
				confirmButtonText: `Yes`,
			}).then((result) => {
				if (result.isConfirmed) {
					axios.delete(config.api_path + '/incident/' + item.id)
						.then(response => {
							this.getDataEquipment();
							this.getDataVehicle();
							this.getDataAll();
							Swal.fire({
								title: 'Success!',
								text: "Incident successfully deleted",
								icon: 'success',
							})
						})
				}
			})
		},




		getVehicle() {
			axios.get(config.api_path + '/reference/vehicle-list', {
				params: {
				}
			})
				.then(response => {
					this.vehicleList = response.data;
				})
		},

		getEquipment() {

			axios.get(config.api_path + '/reference/equipment-list')
				.then(response => {
					this.equipmentList = response.data;
					this.equipmentList.data = this.equipmentList.data.map((value, index) => {
						value.value = value.id;
						value.label = value.asset_no;
						return value;
					})
				})

		},
		download(){ 
	    	var incident_no = this.filter.incident_no;
	    	if(incident_no == "all" || incident_no == null){
	    		incident_no = ""
	    	}
			let report_by = this.filter.report_by;
	    	if(report_by == "all" || report_by == null){
	    		report_by = ""
	    	}  
			let asset_type = this.filter.asset_type;
	    	if(asset_type == "all" || asset_type == null){
	    		asset_type = ""
	    	}     
			let incident_date_from = '';
	    	if(this.filter.incident_date_from != ""){ 
	    		incident_date_from = moment(this.filter.incident_date_from).startOf('day') / 1000;
	    	}
			let incident_date_to = '';
	    	if(this.filter.incident_date_to != ""){ 
	    		incident_date_to = moment(this.filter.incident_date_to).startOf('day') / 1000;
	    		incident_date_to += 86399;
	    	}
			this.$showLoading(true)
			console.log(config);
	    	axios.get(config.api_path+'/reports/incident?company_id='+config.global_user_data.company_id
				+'&is_trucking='+this.is_trucking()
				+'&incident_no='+this.filter.incident_no
				+'&report_by='+this.filter.report_by
				+'&asset_type='+this.filter.asset_type
				+'&incident_date_from='+incident_date_from
				+'&incident_date_to='+incident_date_to
				+'&limit=20')
	    	.then(response=>{
				this.$showLoading(false)
	    		window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 
	    		setTimeout(() => {
					axios.get(`${config.api_path}/remove-file`,{
						params : {
							filename : response.data.file
						}
					})	
				}, 2000);
	    	})
			.catch(err => {
				this.$showLoading(false)
			})
	    },

		//     getCustomerList(){

		//     	axios.get(config.api_path+'/customer?page=1&limit=10000000')
		//     	.then(response => {
		//     		this.customerList = response.data; 
		//     	})

		//     },

		// },
		// watch: {
		// dataParams: {
		// 	handler(val, oldVal){
		// 		if(this.dataParams.class_type == 'vehicle'){
		// 			if(this.dataParams.vehicle_id != ""){
		// 				var selected_vehicle = this.vehicleList.data.filter((value, index)=>{
		// 					if(value.id == this.dataParams.vehicle_id){
		// 						// this.dataParams.current_location_id = value.origin_id;
		// 						// this.dataParams.type = value.vehicle_type.setting_name;
		// 					}
		// 				})
		// 			}
		// 		}
		// 		if(this.dataParams.class_type == 'equipment'){
		// 			if(this.dataParams.equipment_id != ""){
		// 				var selected_vehicle = this.equipmentList.data.filter((value, index)=>{
		// 					if(value.id == this.dataParams.equipment_id){
		// 						// this.dataParams.current_location_id = value.origin_id;
		// 						// this.dataParams.type = value.type;
		// 					}
		// 				})
		// 			}
		// 		}
		// 	},
		// 	deep: true
		// }
		// }
	}
}
</script>
